<script>

import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  data() {
		return {
      submitted:false,
      goals: [
      { _id: 'none',name : this.$t('goals.none')},
        { _id: 'purchase',name : this.$t('goals.purchase')},
        { _id: 'cart',name : this.$t('goals.cart')},
        { _id: 'like',name : this.$t('goals.like')},
        { _id: 'view',name : this.$t('goals.view')},
      ],
      auxGoal: { _id: 'purchase',name : this.$t('goals.purchase')},
      goal_times : [
        {id: 'hours', label:'Hours'},
        {id: 'days', label:'Days'},
      ],
      auxDay: 1,
      auxHour: 0,
      timeError: false
    }
  },
  validations: {
    journey: {
      name: { required }
    }
  },
  mounted(){
  
 },
 components:{Multiselect},
 methods:{

  onTimeChanged(){
    if(!this.auxDay)
      this.auxDay = 1;

    if(!this.auxHour)
      this.auxHour = 0;

    this.journey.goal.time = this.auxDay * 24 + this.auxHour;
    this.timeError = this.journey.goal.time > (90 * 24)
  },
  onGoalSelected(){
      if(!this.journey.goal)
        this.journey.goal = {}

      this.journey.goal._id = this.auxGoal._id; 
    },
    
  onConfirmSettingsJourneyClicked(){
    this.submitted = true;

    this.$v.$touch();

    if (this.$v.$invalid || this.timeError) {
      return;
    } 

    this.$emit('editJourneySettings', this.journey);
  },
  onWorkflowClicked(){
    this.$router.push({
        path: `/journey?id=${this.journey._id}`,
        }
    ).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
    });
  },
  onElegibilyChanged(frequency){
    this.journey.frequency = frequency;
  }
 },
 emits: ['editJourneySettings', 'cancelSettingsJourney'],
 props:{
  journey: Object
 } ,
 watch : {
  journey: {
    immediate:true,
    handler(newVal){
      if(!newVal){
        this.journey = {
          status:0,
          project: localStorage.getItem("current_project"),
          journey:{},
          settings:{
            anonymous:false
          }
        }
      }else{
        if(!newVal.frequency)
          newVal.frequency = 'multiple_per_customer'

        if(!newVal.settings)
          newVal.settings = {
            anonymous:false
          }

        if(newVal.goal){
          this.auxGoal = this.goals.filter(g=>g._id == newVal.goal._id).length > 0 ? this.goals.filter(g=>g._id == newVal.goal._id)[0] : this.auxGoal;
          if(newVal.goal.time){
            this.auxDay = Math.floor(newVal.goal.time / 24)
            this.auxHour = newVal.goal.time % 24;
            this.onTimeChanged();
          }
        }else{
          newVal.goal = {
            _id : 'purchase',
            time: 24
          }
        }
      }
    }
  }
 } 
  
}

</script>

<template>
  <div>
    <div class="row">
        <div class="mb-3">
            <label for="promotionName">{{$t('goals.goal')}}*</label>
            <div class="">
              <multiselect
                :options="goals" 
                v-model="auxGoal"
                track-by="_id" 
                label="name"
                selectLabel=""
                deselectLabel=""
                :allowEmpty="false"
                class="me-2"
                @input="onGoalSelected"
                :show-labels="false"
              />
            </div>
            <div class="d-inline-flex align-items-baseline mt-1" v-if="auxGoal?._id != 'none'">
              {{$t('goals.goal_next')}} 
              <b-form-input class="form-control form-control-sm ms-2 me-2" type="number" v-model="auxDay" style="width: 60px;" @input="onTimeChanged"></b-form-input>
              {{$t('goals.goal_days')}}
              <b-form-input class="form-control form-control-sm ms-2 me-2" type="number" v-model="auxHour" style="width: 60px;" @input="onTimeChanged"></b-form-input>
              {{$t('goals.goal_hours')}} 
            </div>
            <div v-if="submitted && timeError" class="invalid-feedback" style="display: block;">
              {{ $t('goals.goal_time_error') }}
            </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
            <label for="promotionName">{{$t('journeys.elegibility')}}*</label>
            <div>
              <div class="form-check form-check-inline font-size-16">
                <input
                  class="form-check-input"
                  type="radio"
                  name="paymentoptionsRadio"
                  id="paymentoptionsRadio1"
                  :checked="journey.frequency && journey.frequency == 'once_per_customer'"
                  @input="onElegibilyChanged('once_per_customer')"
                />
                <label class="form-check-label font-size-13" for="paymentoptionsRadio1">
                  {{$t('journeys.elegibility_once_per_customer')}}
                </label>
              </div>
              <div class="form-check form-check-inline font-size-16">
                <input
                  class="form-check-input"
                  type="radio"
                  name="paymentoptionsRadio"
                  id="paymentoptionsRadio2"
                  :checked="!journey.frequency || journey.frequency == 'multiple_per_customer'"
                  @input="onElegibilyChanged('multiple_per_customer')"
                />
                <label class="form-check-label font-size-13" for="paymentoptionsRadio2">
                  {{$t('journeys.elegibility_multiple_per_customer')}}
                </label>
              </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
            <label>{{$t('journeys.allow_anonymus')}}*</label>
            <div class="mb-3">
              <div class='form-check form-switch form-switch-lg'>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="journey.settings.anonymous"
                />
              </div>
          </div>
        </div>
      </div>
    <div class="text-end pt-5 mt-3">
        <b-button variant="secondary" @click="$emit('cancelSettingsJourney')">{{$t('common.cancel')}}</b-button>
        <b-button variant="primary" class="ms-1" @click="onConfirmSettingsJourneyClicked">{{$t('common.confirm')}}</b-button>
    </div>
  </div>
</template>
