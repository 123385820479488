<script>

import { required } from "vuelidate/lib/validators";
import Store from "@/components/personalizations/templates-store.vue"

export default {
  data() {
		return {
      submitted:false,
      showAvailableTemplates: false
    }
  },
  validations: {
    journey: {
      name: { required }
    }
  },
  mounted(){
  
 },
 components:{ Store },
 methods:{
  
  onCreateJourneyClicked(){
    this.submitted = true;

    this.$v.$touch();
    
    if (this.$v.$invalid) {
      return;
    } 

    this.$emit('createJourney', this.journey);
  },
  onWorkflowClicked(){

    if(!this.journey.journey){
        this.showAvailableTemplates = true;
      }else{
        this.$router.push({
            path: `/journey?id=${this.journey._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
      }
  },
  onTemplateClicked(data){
      this.showAvailableTemplates = false;
      
      this.$emit('templateSelected', data);
    }
 },
 emits: ['createJourney', 'cancelCreateJourney', 'templateSelected'],
 props:{
  journey: Object
 } ,
 watch : {
  journey: {
    immediate:true,
    handler(newVal){
      if(!newVal){
        this.journey = {
          status:0,
          project: localStorage.getItem("current_project"),
          journey:{},
          goal : {
            _id : 'purchase',
            time: 24
          }
        }
      }
    }
  }
 } 
  
}

</script>

<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <label>{{$t("journeys.name")}}*</label>
        <input
              v-model="journey.name"
              type="text"
              class="form-control"
              :placeholder="$t('journeys.name_placeholder')"
              :class="{ 'is-invalid': submitted && $v.journey.name.$error }"
            />
            <div v-if="submitted && !$v.journey.name.required" class="invalid-feedback">
              {{$t('journeys.name_required')}}
            </div>
        </div>
    </div>
    <div class="row mb-3">
    <div class="col">
      <label>{{$t("journeys.description")}}</label>
      <input
            v-model="journey.description"
            type="text"
            class="form-control"
            :placeholder="$t('journeys.description_placeholder')"
          />
      </div>
    </div>
    <div class="row" v-if="journey._id!=null">
        <div class="col">
          <button class="btn btn-link" @click="onWorkflowClicked"> <i class="fas fa-route text-primary me-2 interact"></i> Workflow </button>  
        </div>
      </div>
    <div class="text-end pt-5 mt-3">
        <b-button variant="light" @click="$emit('cancelCreateJourney')">{{$t('common.cancel')}}</b-button>
        <b-button variant="primary" class="ms-1" @click="onCreateJourneyClicked">{{$t('common.confirm')}}</b-button>
    </div>
    <b-modal id="templates-modal" size="xl" v-model="showAvailableTemplates" :title="$t('personalizations.experience_variant_start_with')" hide-footer no-close-on-backdrop>
        <Store :hideTypeBadge="true" :hideSubtypeFilter="true" :hideGoalsFilter="true" :showFromScratch="true" @selectStoreTemplate="onTemplateClicked" :showMyTemplates="false" :type="'journey'"/>
    </b-modal>
  </div>
</template>
