<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list";
import NewJourney from "@/components/journeys/new-journey";
import SettingsJourney from "@/components/journeys/settings";
import Store from "@/components/personalizations/templates-store.vue"

import {
	journeyMethods

} from "@/state/helpers";

export default {
  page: {
    title: "Journeys",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    NewJourney,
    SettingsJourney,
    Store
  },
  data() {
    return {
      title: "Journeys",
      items: [],
			currentPage:1,
			perPage:9,
      rows:0,
			isLoading:false,
      journeys:[],
      confirmDelete:false,
      journey: null,
      showPreviewPanel:false,
      empty_config:{
        "title" : this.$t('journeys.empty'),
        "subtitle" : this.$t('journeys.empty_subtitle'),
        "buttonText" : this.$t('journeys.new_journey'),
        icon: "fa-cubes"
      },
      empty_list_config:{
          "title" : this.$t("journeys.empty_search_title"),
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
        },
      pageLoaded: false,
      showNewJourney: false,
      showSettingsJourney: false,
      searching:false,
      query:'',
      showAvailableTemplates: false
    };
  },
  created(){
		this.debounceJourneys = _debounce(this.searchJourneys.bind(this), 1000);
	},
	mounted(){
		this.loadJourneys();
  },
  methods:{
    ...journeyMethods,

    onRemoveJourney(journey){
      this.journey = journey;
      this.confirmDelete = true;
    },
    onConfirmRemoveJourneyClicked(){
      let loader = this.$loading.show();

      this.deleteJourney(this.journey._id).then(()=>{
        this.currentPage=1;
        this.loadJourneys();
        this.$notify({ type: 'success', text: this.$t('journeys.delete_journey_success'),title:  this.$t('journeys.title') });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('journeys.delete_journey_error'),title:  this.$t('journeys.title') });
      }).finally(()=>{
        loader.hide();
      })
    },

		loadJourneys(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
			const params = {
				q : `where[project]=${localStorage.getItem("current_project")}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
			}

			this.getJourneys(params).then((journeys)=>{
        if(journeys&& journeys.data){
          this.journeys= journeys.data;
          this.rows= journeys.totalCount;
          this.pageLoaded = true;
				}
      }).catch(()=>{
        this.$notify({ type: 'error', text:this.$t('journeys.get_journeys_error'),title:  this.$t('journeys.title') });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },
    searchJourneys(query){
      
			let loader = this.$loading.show();
      this.isLoading = true;
      
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("journeys.empty_search_text").replaceAll('#', this.query);

      if(query){
        this.journeys=[];
        this.currentPage = 1;
				const params={
					q: `where[project]=${localStorage.getItem("current_project")}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}

        this.getJourneys(params).then((journeys)=>{
          if(journeys&& journeys.data){
            this.journeys= journeys.data;
            this.rows= journeys.totalCount
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.searching = false;
        this.pageLoaded = false;
        this.loadJourneys();
      }
    },
		onJourneysListPageClicked(){
			this.loadJourneys();
		},	
    onEditJourneyClicked(data){
      this.journey = data;
      this.showNewJourney = true;
    },
    onCreateJourneyClicked(){
      this.journey = null;
      this.showNewJourney = true;
    },
    onPublishOrUnpublishJourney(data){
      let loader = this.$loading.show();
      const journey = {
        _id: data._id,
        status : !data.status
      }
      this.updateJourney(journey).then(()=>{
          this.$notify({ type: 'success', text: this.$t('journeys.update_journey_success'),title:  this.$t('journeys.title') });
          this.loadJourneys();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('journeys.update_journey_error'),title:  this.$t('journeys.title') });
        }).finally(()=>{
          loader.hide();
        })
    },
    onNewJourneyConfirm(journey){

      let loader = this.$loading.show();

      if(journey._id){
        this.updateJourney(journey).then(()=>{
            this.$notify({ type: 'success', text: this.$t('journeys.update_journey_success'),title:  this.$t('journeys.title') });
            this.showNewJourney = false;
            this.loadJourneys();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('journeys.update_journey_error'),title:  this.$t('journeys.title') });
        }).finally(()=>{
          loader.hide();
        })
      }else{
        this.createJourney(journey).then(()=>{
          this.$notify({ type: 'success', text: this.$t('journeys.create_journey_success'),title:  this.$t('journeys.title') });
          this.showNewJourney = false;
            this.loadJourneys();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('journeys.create_journey_error'),title:  this.$t('journeys.title') });
        }).finally(()=>{
          loader.hide();
        })
      }
      
    },
    onNewJourneyCancel(){
      this.showNewJourney = false
    },
    onSettingsJourneyCancel(){
      this.showSettingsJourney = false;
    },
    onSettingsJourneyConfirm(journeySettings){
      const payload = {
        _id: journeySettings._id,
        goal: journeySettings.goal,
        frequency: journeySettings.frequency,
        settings: journeySettings.settings
      }
      let loader = this.$loading.show();

      this.updateJourney(payload).then(()=>{
            this.$notify({ type: 'success', text: this.$t('journeys.update_journey_success'),title:  this.$t('journeys.title') });
            this.showSettingsJourney = false;
            this.loadJourneys();
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('journeys.update_journey_error'),title:  this.$t('journeys.title') });
        }).finally(()=>{
          loader.hide();
        })
    },
    onWorkflowClicked(data){
      // eslint-disable-next-line no-console
      console.log(data);
      if(!data.journey){
        this.journey = data;
        this.showAvailableTemplates = true;
      }else{
        this.$router.push({
            path: `/journey?id=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
      }
      
    },
    onResetSearchClicked(){
      this.query = '';
      this.loadJourneys();
    },
    onSettingsJourneyClicked(data){
      this.journey = data;
      this.showSettingsJourney = true;
    },
    onAnalyticsClicked(data){
      this.$router.push({
            path: `/journey-analyze?journey=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    onTemplateClicked(data){
      this.showAvailableTemplates = false;
      
      let loader = this.$loading.show();
      const payload = {
        _id: this.journey._id,
        journey: data.value
      }
      this.updateJourney(payload).then(()=>{
        this.$router.push({
          path: `/journey?id=${this.journey._id}`,
        }
        ).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        });
            
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('journeys.update_journey_error'),title:  this.$t('journeys.title') });
        }).finally(()=>{
          loader.hide();
        })

    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('journeys.title')" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
				<div class="card-body">
          <div class="row ">
            <div class="col-sm-4 align-self-center">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceJourneys($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="text-sm-end">
                <button
                    type="button"
                    class="btn btn-primary mb-0 me-0"
                    @click="onCreateJourneyClicked"
                    v-if="journeys.length>0 || query!=''">
                    <i class="mdi mdi-plus me-1"></i> {{ $t('journeys.new_journey')}}
                </button>
                </div>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && journeys.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config"  @onButtonClicked="onCreateJourneyClicked" class="mt-5 mb-5" v-if="!isLoading && journeys.length == 0 && query==''"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && journeys.length > 0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">{{ $t('journeys.name')}}</th>
                      <th scope="col">{{ $t('journeys.last_update_date')}}</th>
                      <th scope="col" class="text-end">{{ $t('personalizations.status')}}</th>
                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in journeys" :key="data.id">
                      <td>
                        <h5 class="text-truncate font-size-14 mb-0 interact" v-on:click="onEditJourneyClicked(data)">
                          <a class="text-dark">{{
                            data.name
                          }}</a>
                        </h5>
                        <p class="text-muted mb-0 small interact" v-on:click="onEditJourneyClicked(data)">{{data.description}}</p>
                      </td>
                      <td>
                        {{data.updatedAt ? `${new Date(data.updatedAt).toLocaleDateString()} ${new Date(data.updatedAt).toLocaleTimeString()}` : ''}}
                      </td>
                      <td class="text-end">
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.status}` == 1,
                          'bg-warning': `${data.status}` == 0}">
                            {{ data.status == 1 ? $t('personalizations.status_active') : $t('personalizations.status_inactive') }}
                        </span>
											</td>
                      <td class="text-end align-middle">
                        <i class="fas text-primary me-2 fas fa-chart-line interact" v-on:click="onAnalyticsClicked(data)"></i>
                        <i class="fas text-primary me-2 interact" :class="data.status == 1 ? 'fa-pause' : 'fa-upload'" v-on:click="onPublishOrUnpublishJourney(data)"></i>
                        <i class="fas fa-route text-primary me-2 interact" v-on:click="onWorkflowClicked(data)"></i>
                        <i class="fas fa-cog text-primary me-2 interact" v-on:click="onSettingsJourneyClicked(data)"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditJourneyClicked(data)"></i>
                        <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveJourney(data)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mb-4" v-if="rows > perPage">
                <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                            <b-pagination
                                @input="onJourneyListPageClicked"
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                            ></b-pagination>
                        </ul>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveJourneyClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{ $t('journeys.remove_journey') }}</p>
        </b-modal>

        <b-modal id="templates-modal" size="xl" v-model="showAvailableTemplates" :title="$t('personalizations.experience_variant_start_with')" hide-footer no-close-on-backdrop>
          <Store :hideTypeBadge="true" :hideSubtypeFilter="true" :hideGoalsFilter="true" :showFromScratch="true" @selectStoreTemplate="onTemplateClicked" :showMyTemplates="false" :type="'journey'"/>
      </b-modal>
        
    </div>
    <b-modal v-model="showNewJourney"
     id="modal-center" 
     :title="journey? $t('journeys.edit_journey') :  $t('journeys.new_journey')" 
     title-class="font-18"
     hide-footer>
        <NewJourney @templateSelected="onTemplateClicked" @createJourney="onNewJourneyConfirm" @cancelCreateJourney="onNewJourneyCancel" :journey="journey"></NewJourney>
    </b-modal>
    <b-modal v-model="showSettingsJourney"
     id="modal-center" 
     :title="$t('journeys.settings_journey')" 
     title-class="font-18"
     hide-footer>
        <SettingsJourney @editJourneySettings="onSettingsJourneyConfirm" @cancelSettingsJourney="onSettingsJourneyCancel" :journey="journey"></SettingsJourney>
    </b-modal>
    
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.interact {
  cursor: pointer;
}
</style>
